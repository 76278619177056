import { FreeTimeTableError } from "../../../api-interfaces/shipda-api/freeTime";
import { APP_NAME } from "../../../constants";

/** 어드민에는 표시가 불필요한 쉽다 웹 전용 안내 메세지 */
const shipdaMessage =
  APP_NAME === "shipda-kr" ? "운영매니저에게 문의해주세요." : "";

/** 쉽다 웹, 어드민 공통 에러코드
 * (failureInfo 내 error 로 에러메시지를 보내주는 것으로 변경되어 기본 메시지만 남겨둠)
 */
const getFreeTimeErrorCodeToMessage = ({
  errorCode,
  error,
}: {
  errorCode?: string;
  error?: string | FreeTimeTableError;
}) => {
  const { liner, terminal } = error as FreeTimeTableError;

  const linerAndTerminal = (() => {
    if (liner && terminal)
      return `${liner.name ?? liner.nameEN}, ${
        terminal.nameKR ?? terminal.nameEN
      }의`;

    if (liner) return `${liner.name ?? liner.nameEN}의`;

    if (terminal) return `${terminal.nameKR ?? terminal.nameEN}의`;

    return "";
  })();

  switch (errorCode) {
    case "E1000":
      return `지원하지 않는 선사입니다.`;
    case "E1001":
      return `도착터미널을 식별하지 못했습니다. ${shipdaMessage}`;
    case "E1002":
      return `아직 도착터미널을 식별하지 못했습니다. 입항완료 이후에 다시 시도해주세요.`;
    case "E1003":
      return `선사를 식별하지 못했습니다. ${shipdaMessage}`;
    case "E1004":
      return `아직 선사를 식별하지 못했습니다. 출항완료 이후에 다시 시도해주세요.`;
    case "E1005":
      return `수입 C조건의 운송은 현재 프리타임을 제공할 수 없습니다. 입항완료 이후에 운영매니저에게 문의해주세요.`;
    case "E1006":
      return `컨테이너 데이터 확보에 실패했습니다. ${shipdaMessage}`;
    case "E1020":
      return `본 운송의 ${linerAndTerminal} 프리타임 부여정책이 확인되지 않습니다. ${shipdaMessage}`;
    case "E1021":
      return `본 운송의 ${linerAndTerminal} 요금 정책이 확인되지 않습니다. ${shipdaMessage}`;
    default:
      return `프리타임 조회 중 오류가 발생했습니다. ${shipdaMessage}`;
  }
};

export { getFreeTimeErrorCodeToMessage };
