import {
  Children,
  Dispatch,
  isValidElement,
  ReactNode,
  SetStateAction,
} from "react";

import { SellernoteAPIType } from "../../types/common/common";

import {
  APP_BUILD_INFO,
  APP_TYPE,
  IS_UNDER_PRODUCTION,
  LOCAL_PRINTER_URL,
  NETWORK_PRINTER_FOR_CJ_URL,
  NETWORK_PRINTER_FOR_HANJIN_AND_OVERSEAS_URL,
} from "../../constants";
import { toFormattedDate } from "./date";
import regEx from "./regEx";

/**
 * nested key로 object를 업데이트하기 위해 만든 함수
 * object와 key받고, 해당 key(nested key지원)의 value가 수정된 object를 반환함
 * (원본 object를 수정하고 반환함에 유의)
 *
 * @param obj - 객체
 * @param keyPath - string. nested key의 경우 '.'으로 구분
 * @param value - key에 할당하고자하는 value
 * @returns 수정된 객체
 *
 * @remarks 원본 객체를 수정하므로 주의가 필요합니다.
 */
export function getUpdatedObject(obj: any, keyPath: string, value: any) {
  const keyArr = keyPath.split(".");
  let current: any = obj;
  const lastIndex = keyArr.length - 1;

  for (let i = 0; i <= lastIndex; i++) {
    if (!current) return obj;

    if (i === lastIndex) {
      current[keyArr[i]] = value;
      return obj;
    }

    if (typeof current[keyArr[i]] !== "number" && !current[keyArr[i]]) {
      current[keyArr[i]] = {};
    }
    current = current[keyArr[i]];
  }

  return obj;
}

/**
 * 브라우저에서 uri를 통해 다운로드할 때 사용
 *
 * @param document - Document 객체
 * @param uri - 다운로드할 파일의 URI
 * @param fileName - (선택사항) 다운로드할 파일의 이름
 * @returns void
 *
 * @remarks 결과 파일 이름 설정이 안 되는 경우도 있습니다.
 */
export function downloadFromURI(
  document: Document,
  uri: string,
  fileName?: string
) {
  const a = document.createElement("a");
  a.href = uri;
  if (fileName) {
    a.download = fileName;
  }
  document.body.appendChild(a); // IE때문에 body에 추가해줘야함
  a.click();
  a.remove();
}

/**
 * 외부 script를 로드한 후, 그 script에 의존하는 코드(callback)를 실행한다.
 * 이미 로드되있다면, 새로 로드하지 않고 코드(callback)를 실행한다.
 *
 * @param scriptUrl - 로드할 외부 script의 URL
 * @param callback - script 로드 후 실행할 콜백 함수
 * @returns void
 */
export function loadExternalScriptAndRunDependentCallback(
  scriptUrl: string,
  callback: () => void
) {
  const allScripts = document.getElementsByTagName("script");

  let targetScript: any = null;
  for (let i = 0; i < allScripts.length; i++) {
    const item = allScripts.item(i);
    if (item && item.src === scriptUrl) {
      targetScript = item;
    }
  }

  if (targetScript) {
    callback();
  } else {
    targetScript = document.createElement("script");
    targetScript.setAttribute("src", scriptUrl);
    document.head.appendChild(targetScript);
    targetScript.onload = callback;
  }
}

/**
 * 옵션 리스트에서 선택할 수 있는 옵션이 하나뿐인 경우 해당 옵션을 자동으로 선택한다.
 *
 * @param optionList - 선택할 옵션 리스트
 * @param setCallback - 선택된 옵션을 설정할 콜백 함수
 * @returns void
 */
export function setOneIfHaveNoChoice<T>(
  optionList: T[] | undefined,
  setCallback: (v: T) => void
) {
  if (!optionList || !optionList.length) {
    return;
  }

  if (optionList.length === 1) {
    setCallback(optionList[0]);
  }
}

/**
 * 총 페이지 개수를 계산하여 반환
 * @param pageUnit - 페이지 당 아이템 개수
 * @param totalSize - 총 아이템 개수
 * @returns 총 페이지 개수
 */
export function getPageSize(pageUnit: number, totalSize?: number) {
  if (pageUnit && totalSize) {
    let pageSize = totalSize / pageUnit;

    if (pageSize % 1 > 0) {
      pageSize = Math.floor(totalSize / pageUnit) + 1;
    }

    return pageSize;
  }

  return 1;
}

/**
 * 빈 객체나 빈 배열 체크.
 * @param param - 체크할 객체 또는 배열
 * @returns 빈 객체나 배열이면 true, 아니면 false
 */
export function isEmptyObjectOrArray(
  param: Array<any> | { [key: string]: any }
) {
  return Object.keys(param).length === 0;
}

/**
 * 빈 객체나 빈 배열이 아닌지 체크.
 * @param param - 체크할 객체 또는 배열
 * @returns 빈 객체나 배열이 아니면 true, 빈 객체나 배열이면 false
 */
export function isNotEmptyObjectOrArray(
  param: Array<any> | { [key: string]: any }
) {
  return !isEmptyObjectOrArray(param);
}

/**
 * 타입을 문자열로 반환
 * 'String', 'Number', 'Boolean', 'Object' 등 앞글자 대문자로 반환
 * @param value - 타입을 확인할 값
 * @returns 타입을 나타내는 문자열
 */
export function getTypeToString(value: any) {
  return Object.prototype.toString.call(value).slice(8, -1);
}

/**
 * 객체의 빈 속성 제거
 * @param object - 빈 속성을 제거할 객체
 * @returns 빈 속성이 제거된 객체
 */
export function removeEmptyPropertiesOfObject(object: { [key: string]: any }) {
  Object.keys(object).forEach((key) => {
    getTypeToString(object[key]) === "Object"
      ? isEmptyObjectOrArray(object[key]) && delete object[key]
      : !object[key] && delete object[key];
  });

  return object;
}

/**
 * 현재는 Contents의 경우만 baseUrl이 구분되지만, 추후 더 늘어날 것이라고 함
 * @param apiType - API 타입
 * @returns API 타입에 따른 baseUrl
 */
export function getBaseURLByAPIType(apiType?: SellernoteAPIType) {
  if (apiType === "LocalPrinter") {
    return LOCAL_PRINTER_URL;
  }

  if (apiType === "NetworkPrinterForHanjinAndOverseas") {
    return NETWORK_PRINTER_FOR_HANJIN_AND_OVERSEAS_URL;
  }

  if (apiType === "NetworkPrinterForCJ") {
    return NETWORK_PRINTER_FOR_CJ_URL;
  }

  if (APP_TYPE === "ShipDa") {
    switch (apiType) {
      case "Contents": {
        return process.env.NEXT_PUBLIC_CONTENTS_API_URL;
      }
      case "BofulDefault": {
        return process.env.NEXT_PUBLIC_BOFUL_API_URL;
      }
      case "BofulDashboard": {
        return process.env.NEXT_PUBLIC_BOFUL_DASHBOARD_API_URL;
      }
      case "ShipdaDefaultNew": {
        return process.env.NEXT_PUBLIC_API_URL_NEW;
      }
      case "ShipdaAdminDefault": {
        return process.env.REACT_APP_BASE_URL;
      }
      case "ShipdaAdminDefaultNew": {
        return process.env.REACT_APP_ADMIN_URL;
      }
      default: {
        return process.env.NEXT_PUBLIC_API_URL;
      }
    }
  }

  if (APP_TYPE === "Boful" || APP_TYPE === "BofulMobile") {
    return process.env.REACT_APP_API_URL;
  }

  if (APP_TYPE === "ContentAdmin") {
    switch (apiType) {
      case "Contents": {
        return process.env.REACT_APP_CONTENT_API_URL;
      }
      case "ShipdaAdminDefaultNew": {
        return process.env.REACT_APP_ADMIN_URL;
      }
      default: {
        return process.env.REACT_APP_CONTENT_API_URL;
      }
    }
  }
}

/**
 * 객체 값 비교.
 * @param obj1 - 비교할 첫 번째 객체
 * @param obj2 - 비교할 두 번째 객체
 * @returns 두 객체가 같으면 true, 다르면 false
 */
export function checkEqualObject(
  obj1: { [key: string]: any },
  obj2: { [key: string]: any }
) {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}

/**
 * 두 객체나 배열이 깊은 비교로 동일한지 체크
 * @param a - 비교할 첫 번째 객체나 배열
 * @param b - 비교할 두 번째 객체나 배열
 * @returns 두 객체나 배열이 같으면 true, 다르면 false
 */
export function deepEqualObjectOrArray<T>(a: T, b: T) {
  if (a === b) return true;

  if (a == null || typeof a != "object" || b == null || typeof b != "object")
    return false;

  if (Array.isArray(a) && Array.isArray(b)) {
    if (a.length !== b.length) return false;
    for (let i = 0; i < a.length; i++) {
      if (!deepEqualObjectOrArray(a[i], b[i])) return false;
    }
    return true;
  }

  const keysA = Object.keys(a),
    keysB = Object.keys(b);

  if (keysA.length !== keysB.length) return false;

  for (const key of keysA) {
    if (
      !keysB.includes(key) ||
      !deepEqualObjectOrArray((a as any)[key], (b as any)[key])
    )
      return false;
  }

  return true;
}

/**
 * 두 배열이 동일 요소들을 가지고 있는지 체크 (배열 내 요소 순서 무관)
 * @param arr1 - 비교할 첫 번째 배열
 * @param arr2 - 비교할 두 번째 배열
 * @returns 두 배열이 같으면 true, 다르면 false
 */
export function checkEqualArray(arr1: unknown[], arr2: unknown[]) {
  if (arr1.length !== arr2.length) return false;

  const sortedArr1 = [...arr1].sort();
  const sortedArr2 = [...arr2].sort();

  return sortedArr1.every((val, index) => val === sortedArr2[index]);
}

/**
 * 객체 리스트 배열에서 특정 속성으로 분류된 객체 얻기
 * @param objectArray - 객체 리스트 배열
 * @param property - 분류할 속성
 * @returns 분류된 객체
 */
export function getGroupedObjectByProperty(
  objectArray: any[],
  property: string | symbol
) {
  return objectArray.reduce(function (acc, obj) {
    const key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);

    return acc;
  }, {});
}

/**
 * falsy 값을 null로 변환하는 함수
 *
 * @param value - 변환할 값 (문자열, 숫자, null 가능)
 * @returns 변환된 값 (null 또는 원래 값)
 */
export const setFalsyValueToNull = (value?: string | number | null) =>
  !value ? null : value;

/**
 * Input 상태를 변경하는 함수
 *
 * @param key - 상태를 변경할 키
 * @param value - 변경할 값
 * @param state - 현재 상태 객체
 * @param setState - 상태를 설정하는 함수
 * @returns void
 *
 * @remarks TODO: 적용되어 있는 부분 handleInputFalsyValueToNullChange 함수로 변경하고 삭제할 것.
 */
export function handleChangeInputState<T>({
  key,
  value,
  state,
  setState,
}: {
  key: keyof T;
  value?: string | number | null;
  state: T;
  setState: Dispatch<SetStateAction<T>>;
}) {
  return setState({ ...state, [key]: setFalsyValueToNull(value) });
}

/**
 * 파일 확장자가 오피스 파일인지 체크하는 함수
 *
 * @param fileExtension - 파일 확장자
 * @returns 오피스 파일이면 true, 아니면 false
 */
export const checkIsOfficeFile = (fileExtension: string | undefined) => {
  switch (fileExtension) {
    case "doc":
    case "docx":
    case "ppt":
    case "pptx":
    case "xls":
    case "xlsx":
    case "rtf":
    case "txt":
      return true;
    default:
      return false;
  }
};

/**
 * 배열 메서드 사용 시, undefined 나 null 을 반환할 가능성이 전혀 없음에도
 * 반환 타입이 T | undefined 으로 고정되어 있으므로, undefined를 미리 체크하여 T 만 반환하는 헬퍼 함수.
 *
 * @param argument - 체크할 값
 * @param message - 에러 메시지 (선택사항)
 * @returns 체크된 값
 *
 * @throws TypeError - 값이 undefined 또는 null인 경우
 */
export function ensureResult<T>(
  argument: T | undefined | null,
  message = "This value was promised to be there."
): T {
  if (argument === undefined || argument === null) {
    throw new TypeError(message);
  }

  return argument;
}

/**
 * data(object) 내의 value 의 타입으로 key 이름을 찾는 함수.
 *
 * @param data - 객체 데이터
 * @param typeOfValue - 찾고자 하는 값의 타입
 * @returns 해당 타입의 값을 가진 key 이름
 *
 * @throws TypeError - 해당 타입의 값을 가진 key가 없는 경우
 */
export function findKeyNameAsTypeOfValue<T extends object>(
  data: T,
  typeOfValue: string
) {
  return ensureResult(
    (Object.keys(data) as Array<keyof T>).find(
      (key: keyof T) => typeof data[key] === typeOfValue
    )
  );
}

/**
 * 특수 문자를 제거하는 함수
 *
 * @param event - 입력 이벤트
 * @param setState - 상태를 설정하는 함수
 * @returns void
 */
export const sanitizeSpecialCharacters = (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  setState: (value: React.SetStateAction<string>) => void
) => {
  const regExp = regEx.special;

  if (regExp.test(event.target.value)) {
    return setState(event.target.value.replace(regExp, ""));
  } else {
    setState(event.target.value);
  }
};

/**
 * Form에 InputText가 여러 개 있는 경우 setValue할 때 사용하기 위한 유틸함수
 *
 * @param setFormState - Form 상태를 설정하는 함수
 * @returns 키와 값을 받아 상태를 변경하는 함수
 */
export const handleFormChange =
  <FormState, Key extends keyof FormState>(
    setFormState: Dispatch<SetStateAction<FormState>>
  ) =>
  (key: Key) =>
  (value: FormState[Key]) =>
    setFormState((prevFormState) => ({ ...prevFormState, [key]: value }));

/**
 * InputText 상태에 falsy 값을 null 처리하기 위한 유틸함수
 *
 * @param setInputState - Input 상태를 설정하는 함수
 * @returns 키와 값을 받아 상태를 변경하는 함수
 */
export const handleInputFalsyValueToNullChange =
  <InputState, Key extends keyof InputState>(
    setInputState: Dispatch<SetStateAction<InputState>>
  ) =>
  (key: Key) =>
  (value: string | number | undefined | null) =>
    setInputState((prevInputState) => ({
      ...prevInputState,
      [key]: setFalsyValueToNull(value),
    }));

/**
 * 아무것도 하지 않는 함수 (noop)
 *
 * @returns void
 */
export const noop = () => {};

/**
 * undefined 값을 null로 변환하는 함수
 *
 * @param value - 변환할 값 (문자열 또는 숫자)
 * @returns 변환된 값 (null 또는 원래 값)
 */
export function setUndefinedToNull<Value extends string | number>(
  value: Value | undefined
): Value | null {
  if (value === undefined) {
    return null;
  }

  return value;
}

/**
 * boolean 조건을 예, 아니오, "-"로 리턴해주는 함수
 *
 * @param value - boolean 조건 값
 * @param showsOx - OX로 표시할지 여부 (선택사항)
 * @returns 예, 아니오, 또는 "-"
 */
export function changeBooleanValueToKr(
  value: boolean | null | undefined,
  showsOx?: boolean
) {
  if (value === undefined || value === null) {
    return "-";
  }
  if (value) {
    return showsOx ? "O" : "예";
  }
  return showsOx ? "X" : "아니오";
}

/**
 * 객체 리스트 배열에서 특정 속성으로 분류된 검색 리스트 얻기
 *
 * @param pageType - 페이지 타입
 * @param searchList - 검색 리스트 배열
 * @returns 분류된 검색 리스트 객체
 */
export function getGroupedSearchListByProperty({
  pageType,
  searchList,
}: {
  pageType: "singleSku" | "groupSku" | "material" | "receiving" | "shipping";
  searchList: {
    searchKind: string;
    searchTerm: string;
  }[];
}) {
  return searchList.reduce<{
    [key: string]: string[];
  }>((acc, obj) => {
    const searchKind = (() => {
      if (obj.searchKind === "company") {
        return "companyNames";
      }

      if (pageType === "receiving" && obj.searchKind === "itemName") {
        return "productNames";
      }

      return `${obj.searchKind}s`;
    })();

    if (!acc[searchKind]) {
      return {
        ...acc,
        [searchKind]: [obj.searchTerm],
      };
    }

    return {
      ...acc,
      [searchKind]: [...acc[searchKind], obj.searchTerm],
    };
  }, {});
}

/**
 * 빌드정보를 console로 출력하는 함수
 * - (보안상) production 환경에서는 출력하지 않는다.
 *
 * @returns void
 */
export function printBuildInfo() {
  if (IS_UNDER_PRODUCTION) return;

  if (!APP_BUILD_INFO) return;

  console.debug("####################### BUILD INFO #######################");
  console.debug(
    `####### BuiltAt: ${toFormattedDate(
      APP_BUILD_INFO.builtAt,
      "YYYY.MM.DD HH:mm:ss Z"
    )}`
  );
  console.debug(`####### Branch: ${APP_BUILD_INFO.gitBranch}`);
  console.debug(`####### Commit ${APP_BUILD_INFO.gitCommitSha}`);
  console.debug("##########################################################");
}

/**
 * 주어진 시간(ms)만큼 지연시키는 함수
 *
 * @param ms - 지연시킬 시간 (밀리초)
 * @returns Promise 객체
 */
export const delay = (ms: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

/**
 * 주어진 URL의 파일을 다운로드하는 함수
 *
 * @param url - 다운로드할 파일의 URL
 * @returns void
 */
export const downloadFile = async (url: string) => {
  const a = document.createElement("a");
  a.href = url;
  a.style.display = "none";

  document.body.append(a);
  a.click();

  // multi-download 라이브러리 기준 크롬 이슈로 인해 딜레이를 줌
  await delay(100);

  a.remove();
};

/**
 * 여러 파일을 다운로드하는 함수
 * source) https://sindresorhus.com/multi-download/
 *
 * @param urlList - 다운로드할 파일들의 URL 배열
 * @returns void
 */
export const downloadMultiFile = async (urlList: string[]) => {
  for (const [index, url] of urlList.entries()) {
    // 다중 파일 다운로드 시 가장 마지막 요청 이외에는 취소되는 이슈로 인해 딜레이를 줌
    await delay(index * 1000);
    downloadFile(url);
  }
};

/**
 * children prop에 있는 특정 컴포넌트를 찾아주는 함수
 *
 * @param children - 부모 컴포넌트의 children prop
 * @param targetComponent - children에서 찾으려고 하는 특정 컴포넌트
 * @returns children에서 targetComponent와 일치하는 특정 컴포넌트 배열
 */
export function getTargetComponentFromChildren(
  children: ReactNode,
  targetComponent: JSX.Element
) {
  const targetComponentType = targetComponent.type;

  const childrenArray = Children.toArray(children);

  return childrenArray
    .filter(
      (child) => isValidElement(child) && child.type === targetComponentType
    )
    .slice(0, 2);
}

/**
 * 두 개의 객체를 합치는 함수
 *
 * @param target - 합쳐질 객체
 * @param source - 합칠 객체
 * @returns 합쳐진 객체
 */
export function mergeObjects<TObject extends object, TSource extends object>(
  target: TObject,
  source: TSource
): TObject & TSource {
  const output: Partial<TObject> = { ...target };

  for (const [key, value] of Object.entries(source)) {
    if (value && typeof value === "object" && !Array.isArray(value)) {
      output[key as keyof TObject] = mergeObjects(
        target[key as keyof TObject] || {},
        value
      );
    } else {
      output[key as keyof TObject] = value;
    }
  }

  return output as TObject & TSource;
}

/**
 * 원본 배열에서 랜덤으로 선택한 배열을 반환하는 함수
 * 원본 배열에서 이미 선택한 요소 (반환되는 배열에 이미 추가한 요소) 는 제외하고 선택합니다.
 *
 * @param originalArray - 선택할 원본 배열
 * @param numberToSelect - 선택할 개수
 * @returns 원본 배열보다 선택할 개수가 크면 undefined 반환 or 중복되지 않은 랜덤 선택 배열
 */
export function getRandomSelectedArray<T>(
  originalArray: T[],
  numberToSelect: number
) {
  if (numberToSelect > originalArray.length) return;

  const copiedArray = [...originalArray];

  return Array.from({ length: numberToSelect }, () => {
    const randomIndex = Math.floor(Math.random() * copiedArray.length);

    return copiedArray.splice(randomIndex, 1);
  }).flat();
}

/**
 * 랜덤으로 섞인 배열을 반환하는 함수
 *
 * @param array - 섞을 배열
 * @returns 랜덤으로 섞인 배열
 */
export function getRandomSortedArray<T>(array: T[]) {
  return array.sort(() => Math.random() - 0.5);
}

export function checkIsValidEveryValueOfArray<T>(
  array: T[],
  isTruthy?: (value: T) => boolean
) {
  if (!Array.isArray(array) || !array.length) return false;

  if (typeof array[0] === "object" && !isTruthy) {
    /** @example checkIsValidEveryValueOfArray(array, ({ value }) => Boolean(value)) */
    throw new Error("배열내 객체에 대해 isTruthy 함수를 제공해야 합니다.");
  }

  return array.every(isTruthy || Boolean);
}
